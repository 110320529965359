import { Analytics } from '@ycos/analytics'
import { trackOnTargetClick, createSiteMerchandisingEntry } from '../analytics-track'
import { getComponentBySegment, getUserSegments, isSegmentedExperience } from '../components/component-segmented-experience/utils'
import productCarouselLayoutVariants from '../components/component-product-carousel/layoutVariant'
import productFeedLayoutVariants from '../components/component-product-feed/layoutVariant'

export const siteMerchandisingRule = (siteMerchandisingItems = [], page) => ({ item, parent }) => {
  let entry = createSiteMerchandisingEntry(item, page.pageKey, parent, item.additionalCTA)

  if (isSegmentedExperience(item)) {
    const userSegments = getUserSegments()
    const component = getComponentBySegment(item.items, userSegments)
    entry = createSiteMerchandisingEntry(component, page.pageKey, item, item.additionalCTA)
  }

  if (isSegmentedExperience(parent)) return
  if (!entry) return

  siteMerchandisingItems.push(entry)
  return siteMerchandisingItems
}

export const doesComponentRequireMultiTracking = (layoutVarient) => ['osc-takeover', 'osc-ton-sale-banner-ab-test'].includes(layoutVarient)

export const siteMerchandisingAdditionalCTARules = (siteMerchandisingItems = [], page, publicRuntimeConfig) => ({ item, parent }) => {
  const { targets, additionalCTA } = item
  const requireAdditionalTracking = doesComponentRequireMultiTracking(item.layoutVariant)

  // TODO: Revert after WEB00526/WEB00527 is done
  // Control component needs data from this rule, so we modify the parent to pass the updated info to the children
  if (item.layoutVariant !== 'osc-ton-sale-banner-ab-test') {
    if (!requireAdditionalTracking || (!targets && !additionalCTA)) {
      return
    }
  }
  // END OF WEB00526/WEB00527

  const {
    brand: { brandId: brand },
    gtmAnalytics
  } = publicRuntimeConfig

  const hasDeprecatedCTAs = (!targets || targets.length === 0) && additionalCTA && additionalCTA.length > 0
  const actualTargets = item.layoutVariant === 'osc-ton-sale-banner-ab-test' ? item.items[0].targets : targets

  const ctas = !hasDeprecatedCTAs
    ? actualTargets
    : additionalCTA.map(({ link = '', anchorPointName = '' }) => ({
        callToActionEnabled: false,
        anchorPointEnabled: false,
        target: {
          type: 'page',
          pageURL: link,
          teaserTitle: anchorPointName
        }
      }))

  const newAdditionalCTA = [...ctas].map((callToActionComponent) => {
    const entry = createSiteMerchandisingEntry(item, page.pageKey, parent, callToActionComponent, true)
    siteMerchandisingItems.push(entry)
    return {
      ...callToActionComponent,
      onTargetClick: () => trackOnTargetClick(entry, gtmAnalytics, brand)
    }
  })
  return {
    item: {
      ...item,
      additionalCTA: newAdditionalCTA
    }
  }
}

const getProductAnalyticData = (product, index) => {
  const { masterCategory, partNumber, analyticsAttributes, name, designerName, designerIdentifier, price, onSale, consideredBadge } = product
  const gender = analyticsAttributes?.gender
  return {
    category: {
      primaryCategory: masterCategory?.labelEN || masterCategory?.label,
      subCategory1: masterCategory?.child?.labelEN || masterCategory?.child?.label,
      subCategory2: ''
    },
    productInfo: {
      productID: `${partNumber}`,
      name: analyticsAttributes?.nameEN || name,
      designer: analyticsAttributes?.designerNameEN || designerName,
      designerID: designerIdentifier,
      ...(gender ? { gender } : {})
    },
    attributes: {
      position: index + 1,
      stock: analyticsAttributes?.badge?.type === 'STOCK' ? analyticsAttributes?.badge?.key : 'IN_STOCK',
      discountPercent: `${price?.discount?.amount / price?.discount?.divisor}%` || null,
      saleFlag: !onSale ? 'full price' : 'on sale',
      colour: analyticsAttributes?.colour,
      season: analyticsAttributes?.season,
      department: analyticsAttributes?.department,
      preOrderFlag: analyticsAttributes?.preOrder,
      badges: analyticsAttributes?.badge && [analyticsAttributes?.badge],
      sustainabilityFlag: !!consideredBadge
    },
    price: {
      currency: price?.currency?.label,
      baseFullPrice: `${price?.wasPrice?.amount / price?.wasPrice?.divisor}`,
      basePrice: `${price?.sellingPrice?.amount / price?.sellingPrice?.divisor}`,
      basePriceGBP: null // TODO
    }
  }
}

export const siteMerchandisingProductsRule = (siteMerchandisingListing, products, publicRuntimeConfig) => ({ item }) => {
  const { brand: { brandId: brand } = {}, gtmAnalytics } = publicRuntimeConfig || {}
  const analytics = new Analytics({ isLegacy: !gtmAnalytics, brand })
  if (!products) return
  const layoutVariants = [...productCarouselLayoutVariants, ...productFeedLayoutVariants]
  if (layoutVariants.includes(item.layoutVariant)) {
    const productList =
      products[item.ynapParameter]?.items?.length > 0 &&
      products[item.ynapParameter]?.items?.reduce((acc, item, index) => {
        return acc.concat(getProductAnalyticData(item, index))
      }, [])

    if (productList) {
      siteMerchandisingListing.item = productList
      return {
        siteMerchandisingListing,
        onProductClick: (product, index) => {
          const { masterCategory, partNumber, nameEN, name, designerNameEN, productId, designerName, designerIdentifier, price, consideredBadge } = product
          analytics.trackEvent({
            event: `select_item - ${nameEN}`,
            eventEffect: 'go to product page',
            category: {
              primaryCategory: masterCategory?.labelEN || masterCategory?.label,
              subCategory1: masterCategory?.child?.labelEN || masterCategory?.child?.label,
              subCategory2: ''
            },
            attributes: {
              position: index + 1,
              discountPercent: `${price?.discount?.amount / price?.discount?.divisor}%` || null,
              sustainabilityFlag: !!consideredBadge
            },
            item: [
              {
                productInfo: {
                  productID: `${partNumber}` || productId,
                  name: nameEN || name,
                  designer: designerNameEN || designerName,
                  designerID: designerIdentifier
                },
                price: {
                  currency: price?.currency?.label,
                  baseFullPrice: `${price?.wasPrice?.amount / price?.wasPrice?.divisor}`,
                  basePrice: `${price?.sellingPrice?.amount / price?.sellingPrice?.divisor}`
                }
              }
            ]
          })
        }
      }
    }
  }
}

export default siteMerchandisingRule
