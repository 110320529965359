import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@ycos/component-grid'
import { createComponent } from '@ycos/fela'
import { getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const Container = createComponent(
  'Container',
  () => ({
    width: '100%'
  }),
  'div'
)

const TitleContainer = createComponent(
  'TitleContainer',
  () => ({
    height: '40px',
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px'
  }),
  'div'
)

const TitleContainerLine = createComponent(
  'TitleContainerLine',
  () => ({
    border: '.5px solid #d3d3d3',
    flexGrow: '1'
  }),
  'hr'
)

const Title = createComponent(
  'Title',
  ({ theme }) => ({
    ...theme.typography.styles.Heading.B2.bp1,
    letterSpacing: '0',
    fontSize: '24px',
    fontWeight: 'normal',
    textTransform: 'none'
  }),
  'h2'
)

class FourColumnHomepage extends Component {
  static layoutVariant = ['osc-four-column-homepage']

  render() {
    const { children, item = {} } = this.props
    const { teaserTitle, tags } = item
    const isDeviceSpecific = getTags(tags, 'DeviceType')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Fragment>
          {teaserTitle && (
            <Grid.Row>
              <Grid.Span small={{ width: 2, offset: 0 }} medium={{ width: 5, offset: 0 }}>
                <TitleContainer>
                  <TitleContainerLine />
                </TitleContainer>
              </Grid.Span>
              <Grid.Span small={{ width: 2, offset: 0 }} medium={{ width: 2, offset: 0 }}>
                <TitleContainer>
                  <Title>{teaserTitle}</Title>
                </TitleContainer>
              </Grid.Span>
              <Grid.Span small={{ width: 2, offset: 0 }} medium={{ width: 5, offset: 0 }}>
                <TitleContainer>
                  <TitleContainerLine />
                </TitleContainer>
              </Grid.Span>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Span
              additionalStyles={{
                marginTop: '3px',
                'screen-large': { marginBottom: '0', marginTop: '3px' },
                'screen-medium': { marginTop: '16px', marginBottom: '50px' },
                'screen-small-max': { marginTop: '0', marginBottom: '25px' }
              }}
              small={{ width: 3, offset: 0 }}
              medium={{ width: 6, offset: 0 }}
              large={{ width: 3, offset: 0 }}
            >
              <Container>{children[0]}</Container>
            </Grid.Span>
            <Grid.Span
              additionalStyles={{
                marginTop: '3px',
                'screen-large': { marginBottom: '0', marginTop: '3px' },
                'screen-medium': { marginTop: '16px', marginBottom: '50px' },
                'screen-small-max': { marginTop: '0', marginBottom: '25px' }
              }}
              small={{ width: 3, offset: 0 }}
              medium={{ width: 6, offset: 0 }}
              large={{ width: 3, offset: 0 }}
            >
              <Container>{children[1]}</Container>
            </Grid.Span>
            <Grid.Span
              additionalStyles={{ marginTop: '12px', 'screen-medium': { marginTop: '3px' }, 'screen-small-max': { marginTop: '0', marginBottom: '25px' } }}
              small={{ width: 3, offset: 0 }}
              medium={{ width: 6, offset: 0 }}
              large={{ width: 3, offset: 0 }}
            >
              <Container>{children[2]}</Container>
            </Grid.Span>
            <Grid.Span
              additionalStyles={{ marginTop: '12px', 'screen-medium': { marginTop: '3px' }, 'screen-small-max': { marginTop: '0' } }}
              small={{ width: 3, offset: 0 }}
              medium={{ width: 6, offset: 0 }}
              large={{ width: 3, offset: 0 }}
            >
              <Container>{children[3]}</Container>
            </Grid.Span>
          </Grid.Row>
        </Fragment>
      </VisibilityWrapper>
    )
  }
}

FourColumnHomepage.propTypes = {
  children: PropTypes.array
}

FourColumnHomepage.defaultProps = {
  children: []
}

export default FourColumnHomepage
