import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@ycos/component-grid'
import { createComponent } from '@ycos/fela'
import { hasTag, getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const UnderlapBlock = createComponent(
  'UnderlapBlock',
  ({ isReverse }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    'screen-large': {
      height: '756px',
      width: '540px',
      margin: isReverse ? '40px 90px 40px 0px' : '40px 0px 40px 90px'
    }
  }),
  'div'
)

const OverlapBlockColumn = createComponent(
  'OverlapBlockColumn',
  ({ isReverse }) => ({
    position: 'inherit',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'screen-large': {
      left: isReverse ? '150px' : '-50px',
      transform: 'translateX(0%)'
    }
  }),
  'div'
)

const OverlapBlock = createComponent(
  'OverlapBlock',
  () => ({
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '40px 10px 25px',
    'screen-medium': {
      padding: '70px'
    },
    'screen-large': {
      position: 'absolute',
      width: '380px',
      height: '502px',
      padding: '40px 40px 50px 40px'
    }
  }),
  'div'
)

class OverlapSection extends Component {
  static layoutVariant = ['osc-overlap-section']

  render() {
    const { children, item } = this.props
    const { tags } = item
    const isReverse = hasTag(tags, 'Content.ReverseContentOrder')
    const isDeviceSpecific = getTags(tags, 'DeviceType')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Grid.Row additionalStyles={{ minHeight: ' 500px', flexDirection: isReverse && 'row-reverse' }}>
          <Grid.Span additionalStyles={{ position: 'relative' }} small={{ width: 6, offset: 0 }} large={{ width: 7, offset: 0 }}>
            <UnderlapBlock isReverse={isReverse}>{children[0]}</UnderlapBlock>
          </Grid.Span>
          <Grid.Span additionalStyles={{ position: 'relative' }} small={{ width: 6, offset: 0 }} large={{ width: 5, offset: 0 }}>
            <OverlapBlockColumn isReverse={isReverse}>
              <OverlapBlock>{children[1]}</OverlapBlock>
            </OverlapBlockColumn>
          </Grid.Span>
        </Grid.Row>
      </VisibilityWrapper>
    )
  }
}

OverlapSection.propTypes = {
  children: PropTypes.array
}

OverlapSection.defaultProps = {
  children: [],
  tags: []
}

export default OverlapSection
