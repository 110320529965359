import React, { Component } from 'react'
import { array } from 'prop-types'
import { createComponent } from '@ycos/fela'
import Masonry from 'react-masonry-component'
import { getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const MasonryContent = createComponent(
  'MasonryContent',
  () => ({
    width: '100%',
    padding: '10px'
  }),
  'div'
)

const MasonryPanel = createComponent(
  'MasonryPanel',
  () => ({
    width: '100%',
    breakInside: 'avoid',
    padding: '10px',
    pageBreakInside: 'avoid',
    'screen-medium': { width: '50%' },
    'screen-large': { width: '33.333333%' }
  }),
  'div'
)

const masonryOptions = {
  transitionDuration: 400
}

class ThreeColumnMasonry extends Component {
  static layoutVariant = ['osc-three-column-masonry']

  render() {
    const { item } = this.props
    const { tags } = item
    const isDeviceSpecific = getTags(tags, 'DeviceType')
    const childElements = this.props.children.map((item, i) => {
      return (
        <MasonryPanel key={i}>
          <MasonryContent>{item}</MasonryContent>
        </MasonryPanel>
      )
    })
    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Masonry options={masonryOptions}>{childElements}</Masonry>
      </VisibilityWrapper>
    )
  }
}

ThreeColumnMasonry.propTypes = {
  children: array
}

ThreeColumnMasonry.defaultProps = {
  children: []
}

export default ThreeColumnMasonry
