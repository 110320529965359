import { createComponent } from '@ycos/fela'

const IconType = (type) => {
  const heartIcon = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='54' height='54' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33.121 32.786c4.134-4.04 6.476-7.542 6.476-11.111 0-3.233-1.946-6.285-5.879-6.285-3.823 0-5.393 3.052-6.718 6.037-1.327-2.985-2.895-6.037-6.718-6.037-3.935 0-5.879 3.052-5.879 6.285 0 3.569 2.342 7.07 6.474 11.111a110.072 110.072 0 0 0 6.123 5.5c.995-.83 3.47-2.919 6.121-5.5zM11 21.675C11 15.77 15.242 12 19.994 12c3.824 0 6.188 2.356 7.006 3.66.818-1.305 3.182-3.66 7.006-3.66C38.758 12 43 15.771 43 21.675c0 5.588-4.133 10.078-7.778 13.49-2.233 2.09-6.389 5.97-8.222 7.835-1.833-1.864-5.989-5.746-8.222-7.835C15.131 31.753 11 27.263 11 21.675z' fill='%23000' fill-rule='evenodd'/%3E%3C/svg%3E")`
  const pinIcon = `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h54v54H0z'/%3E%3Cpath stroke='%23000' stroke-width='3' d='M26.903 7C19.225 7 13 13.126 13 20.682c0 .162.005.327.012.493a13.48 13.48 0 0 0 .796 4.11c2.966 9.594 13.095 22.11 13.095 22.11S37.03 34.88 39.997 25.285a13.44 13.44 0 0 0 .796-4.11c.008-.166.013-.331.013-.494C40.806 13.126 34.58 7 26.903 7zm-.282 18.987c-3.06 0-5.542-2.442-5.542-5.454 0-3.012 2.482-5.454 5.542-5.454 3.061 0 5.542 2.442 5.542 5.454 0 3.012-2.481 5.454-5.542 5.454z'/%3E%3C/g%3E%3C/svg%3E")`
  const speedIcon = `url("data:image/svg+xml;chartset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='54' height='54'%3E%3Cdefs%3E%3Cpath id='a' d='M31.005 32.71c8.285 8.546 16.669 10.744 18.915 8.424 1.948-2.01 5.356-4.46.921-8.14-4.435-3.676-5.674-1.868-7.686.206-1.405 1.45-4.958-1.576-8.029-4.742-3.069-3.168-6.001-6.836-4.596-8.284 2.01-2.076 3.762-3.356.198-7.93C29.448 10.6 28.318 10 27.31 10c-1.799 0-3.222 1.904-4.472 3.192-2.249 2.322-.118 10.968 8.167 19.518z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h54v54H0z'/%3E%3Cuse stroke='%23000' stroke-width='2' xlink:href='%23a'/%3E%3Cpath fill='%23000' d='M19 25H0v-2h19zm4 6H9l.127-2H23zm4 5h-9v-2h9z'/%3E%3C/g%3E%3C/svg%3E")`
  const elevateIcon = `url("data:image/svg+xml;chartset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h54v54H0z'/%3E%3Cpath fill='%23000' d='M15.5 17L27 37H4z'/%3E%3Cpath fill='%23000' fill-rule='nonzero' d='M44.814 20H32.186L38.5 30.982 44.814 20zM38.5 37L27 17h23L38.5 37z'/%3E%3C/g%3E%3C/svg%3E")`

  switch (type) {
    case 'Heart':
      return heartIcon
    case 'Pin':
      return pinIcon
    case 'Speed':
      return speedIcon
    case 'Elevate':
      return elevateIcon
    default:
      return null
  }
}

const Icon = createComponent(
  'Icon',
  ({ type }) => ({
    width: '54px',
    height: '54px',
    backgroundImage: IconType(type),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block'
  }),
  'span'
)

export default Icon
