import productCarouselLayoutVariants from '../components/component-product-carousel/layoutVariant'
import productFeedLayoutVariants from '../components/component-product-feed/layoutVariant'

export const WirePids = (products) => ({ item }) => {
  if (!products) return
  
  const layoutVariants = [...productCarouselLayoutVariants, ...productFeedLayoutVariants]
  if (layoutVariants.includes(item.layoutVariant)) {
    const productList = products[item.ynapParameter]
    if (productList) {
      return { products: productList }
    }
  }
}
