import React from 'react'
import { createComponent } from '@ycos/fela'

export const ContentInnerWrapper = createComponent(
  'ContentInnerWrapper',
  ({ contentLeftAlign = true, contentCenterAlign, contentRightAlign }) => ({
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 20px',
    maxWidth: '415px',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    top: 0,
    marginLeft: contentCenterAlign || contentRightAlign ? 'auto' : 0,
    marginRight: contentCenterAlign || contentLeftAlign ? 'auto' : 0,
    'screen-medium': {
      maxWidth: '480px',
      padding: '40px 30px'
    }
  }),
  'div'
)

export const ContentOuter = createComponent(
  'ContentOuter',
  () => ({
    height: '100%',
    width: '100%',
    position: 'relative'
  }),
  'div'
)

const LinkWrapper = createComponent('LinkWrapper', () => ({}), 'a')

// If the video has a link we need to wrap the content itself with an anchor
// due to the video's absolute positioning interfering with the link due to the video becoming out of flow
// and making it impossible for the anchor to know which size it should be.
// Wrapping the content allows for the text in content to have its own link (though it is probably unnecessary)
// while also allowing the video to have a link.
export const ContentOuterWrapper = ({ children, additionalCTA, extraOnClick, video }) => {
  // Click handler passed from parent.
  // since this is used in an AB test extraOnClick contains possible Optimizely tracking and whatever else is passed from grandparent
  const handleClick = ({ cta, extraOnClick }) => {
    // Execute extraOnClick (tracking) and onTargetClick events
    extraOnClick && extraOnClick()
    return cta?.onTargetClick && cta?.onTargetClick()
  }

  return additionalCTA && video ? (
    <LinkWrapper href={additionalCTA.href} onClick={() => handleClick({ cta: additionalCTA, extraOnClick })} data-testid="productcard-linkwrapper">
      <ContentOuter>{children}</ContentOuter>
    </LinkWrapper>
  ) : (
    <ContentOuter>{children}</ContentOuter>
  )
}
