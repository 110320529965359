import React, { Component } from 'react'
import { array } from 'prop-types'
import { Grid } from '@ycos/component-grid'
import { createComponent } from '@ycos/fela'
import { getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const Container = createComponent(
  'Container',
  () => ({
    width: '100%',
    height: '100%'
  }),
  'div'
)

class ThreeColumn extends Component {
  static layoutVariant = ['osc-three-column']

  render() {
    const { item } = this.props
    const { tags } = item
    const isDeviceSpecific = getTags(tags, 'DeviceType')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Grid.Row>
          <Grid.Span additionalStyles={{ marginTop: '0px' }} small={{ width: 6, offset: 0 }} medium={{ width: 4, offset: 0 }}>
            <Container>{this.props.children[0]}</Container>
          </Grid.Span>
          <Grid.Span
            additionalStyles={{ marginTop: '24px', 'screen-medium': { marginTop: '0' } }}
            small={{ width: 6, offset: 0 }}
            medium={{ width: 4, offset: 0 }}
          >
            <Container>{this.props.children[1]}</Container>
          </Grid.Span>
          <Grid.Span
            additionalStyles={{ marginTop: '24px', 'screen-medium': { marginTop: '0' } }}
            small={{ width: 6, offset: 0 }}
            medium={{ width: 4, offset: 0 }}
          >
            <Container>{this.props.children[2]}</Container>
          </Grid.Span>
        </Grid.Row>
      </VisibilityWrapper>
    )
  }
}

ThreeColumn.propTypes = {
  children: array
}

ThreeColumn.defaultProps = {
  children: []
}

export default ThreeColumn
