export const targetRule = (target) => {
  if (target.type === 'page') {
    return {
      ...target,
      href: target
    }
  }
  return target
}

export const TargetUrlRewriterRule = () => {
  return ({ item }) => {
    const isPage = item.type === 'page' && item.key
    if (!item.target && !item.additionalCTA && !isPage) return { item }
    const transformedCTAs = item.additionalCTA && item.additionalCTA.length > 0 ? item.additionalCTA.map((cta) => targetRule(cta) || cta) : item.additionalCTA

    let transformedTarget
    if (isPage) {
      transformedTarget = targetRule({
        ...item,
        teaserTitle: item.callToActionCustomText || item.teaserTitle
      })
    } else {
      transformedTarget = (item.target && targetRule(item.target)) || item.target
    }

    return {
      item: {
        ...item,
        target: transformedTarget,
        additionalCTA: transformedCTAs
      }
    }
  }
}
export default TargetUrlRewriterRule
