import { createComponent } from '@ycos/fela'

const TakeoverText = createComponent(
  'TakeoverText',
  ({ theme }) => ({
    ...theme.homepage.heading,
    fontFamily: 'TheOutnetWebXL',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    textTransform: 'inherit',
    lineHeight: '1.38',
    letterSpacing: '0',
    textAlign: 'center',
    'screen-large': {
      maxWidth: '480px',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    color: '#ffffff',
    margin: '0',
    marginBottom: `${theme.spacingMultiplier * 3.5}px`,
    ':empty': { display: 'none' },
    '&:last-child': { marginBottom: `${theme.spacingMultiplier * 4}px` }
  }),
  'p'
)

export default TakeoverText
