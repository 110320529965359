import { createComponent } from '@ycos/fela'

const TakeoverSubtitle = createComponent(
  'TakeoverSubtitle',
  ({ theme }) => ({
    ...theme.homepage.subTitle,
    fontFamily: 'TheOutnetWebXL',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontSize: '32px',
    lineHeight: '1',
    letterSpacing: '0',
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: `${theme.spacingMultiplier * 4}px`,
    paddingBottom: '0',
    ':empty': { display: 'none' }
  }),
  'h2'
)

export default TakeoverSubtitle
