import { createComponent } from '@ycos/fela'

const PreTitleText = createComponent(
  'PreTitleText',
  ({ theme }) => ({
    ...theme.homepage.preTitle,
    fontFamily: 'TheOutnetWebXL',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: '0',
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: `${theme.spacingMultiplier * 4}px !important`,
    fontSize: theme.typography.styles.Paragraph['4'].bp1.fontSize,
    ':empty': { display: 'none' }
  }),
  'p'
)

export default PreTitleText
