import React from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { createFontStyle } from '@ycos/primitives'
import ProductPrice from './ProductPrice'

const ProductCardContainer = createComponent('ProductCardContainer', ({ theme }) => ({
  padding: `0 ${theme.spacingMultiplier * 2}px`,
  width: '100%',
  scrollSnapAlign: 'start',
  display: 'flex',
  'screen-small-max': {
    width: `calc(50% - ${theme.spacingMultiplier / 2}px)`,
    minWidth: `calc(50% - ${theme.spacingMultiplier / 2}px)`,
    minHeight: '425px',
    ':nth-child(-n + 2)': {
      marginBottom: `${theme.spacingMultiplier * 3}px`
    }
  },
  'screen-medium': {
    width: `calc(25% - ${theme.breakpoints.large.gutterWidth - theme.breakpoints.large.gutterWidth / 4}px)`,
    minWidth: `calc(25% - ${theme.breakpoints.large.gutterWidth - theme.breakpoints.large.gutterWidth / 4}px)`,
    minHeight: '425px'
  },
  'screen-large': {
    minHeight: '450px'
  }
}))

const ProductCardLiner = createComponent('ProductCardLiner', ({ theme }) => ({
  background: theme.typography.colors.PrimaryWhite,
  border: theme.custom.productCard.border,
  width: '100%',
  height: '100%',
  margin: `0 0 ${theme.spacingMultiplier * 2}px 0`,
  'screen-small-max': {
    height: '254px',
    maxHeight: '254px'
  },
  'screen-medium': {
    height: 'auto',
    overflow: 'hidden'
  }
}))

const LinkWrapper = createComponent(
  'LinkWrapper',
  () => ({
    width: '100%',
    height: '100%',
    display: 'block'
  }),
  'a'
)

const ImageWrapper = createComponent(
  'ImageWrapper',
  () => ({
    display: 'block',
    width: '100%'
  }),
  'div'
)

const Image = createComponent(
  'Image',
  () => ({
    display: 'block',
    background: '#eee',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    'screen-small-max': {
      height: '254px',
      maxHeight: '40vh'
    }
  }),
  'img'
)

const ContentWrapper = createComponent(
  'ContentWrapper',
  () => ({
    width: '100%',
    position: 'relative',
    textAlign: 'center'
  }),
  'div'
)
const ProductInformation = createComponent(
  'ProductInformation',
  ({ theme }) => ({
    marginTop: `${theme.spacingMultiplier * 3}px`,
    marginBottom: `${theme.spacingMultiplier * 3}px`,
    minHeight: `${theme.spacingMultiplier * 7.5}px`,
    textAlign: 'center',
    width: '100%',
    whiteSpace: 'initial'
  }),
  'div'
)
const Designer = createFontStyle('Designer', { type: 'Heading', tagName: 'div', name: 'B6' }, ({ theme }) => ({
  color: theme.custom.productCard.nameColor
}))

const Description = createFontStyle('Description', { type: 'Paragraph', tagName: 'div', name: '5' }, ({ theme }) => ({
  color: theme.custom.productCard.descriptionColor,
  display: 'flex-inline',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  lineHeight: '16px',
  WebkitLineClamp: '3',
  'screen-medium': {
    padding: `0 ${theme.spacingMultiplier * 2}px`
  }
}))

export const flattenProdutImages = (product) => {
  const { imageTemplate, imageViews } = product.productColours.find((product) => product.selected) || product.productColours[0] || product

  return [
    imageTemplate.replace('{view}', imageViews[0]).replace('{width}', '300_q80'),
    imageTemplate.replace('{view}', imageViews[1]).replace('{width}', '300_q90')
  ]
}

/**
 * TODO: remove when we move away from F31
 * F31 should be of the form /shop/product/{partNumber}
 */

export const createProductLink = ({ locale, partNumber }) => {
  if (!locale || !partNumber) {
    return null
  }

  return `/${locale.id}/shop/product/${partNumber}`
}

class ProductCard extends React.Component {
  state = {
    mouseOver: false
  }

  toggleImage = (mouseOver) => {
    this.setState({
      mouseOver: mouseOver && window.innerWidth > 1024
    })
  }
  render() {
    const { product, productUrlTemplate, locale, index, onProductClick } = this.props
    const { designerName, name, price, onSale, badges, attributes } = product
    const productLink = createProductLink({ productUrlTemplate, locale, ...product })
    const [inImgUrl, outImgUrl] = flattenProdutImages(product)
    const imgUrl = outImgUrl && this.state.mouseOver ? outImgUrl : inImgUrl

    const considered = attributes?.find((attribute) => attribute?.identifier === 'CONSIDERED')

    return (
      <ProductCardContainer data-automation="product">
        <ProductCardLiner>
          <LinkWrapper
            data-testid="productcard-linkwrapper"
            href={productLink}
            onClick={() => {
              onProductClick(product, index)
            }}
          >
            <ImageWrapper
              data-testid="productcard-image"
              data-automation="imageWrapper"
              onMouseEnter={this.toggleImage.bind(this, true)}
              onMouseLeave={this.toggleImage.bind(this, false)}
            >
              <Image src={imgUrl} />
            </ImageWrapper>
            <ContentWrapper data-automation="content">
              <ProductInformation data-automation="product-information">
                {designerName && (
                  <Designer data-testid="productcard-designer" data-automation="designer">
                    {designerName}
                  </Designer>
                )}
                {name && (
                  <Description data-testid="productcard-description" data-automation="description">
                    {name}
                  </Description>
                )}
                <ProductPrice data-testid="productcard-price" price={price} onSale={onSale} badges={badges} considered={considered} locale={locale} />
              </ProductInformation>
            </ContentWrapper>
          </LinkWrapper>
        </ProductCardLiner>
      </ProductCardContainer>
    )
  }
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    designerName: PropTypes.string,
    productId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    onSale: PropTypes.bool,
    seo: PropTypes.object
  }),
  productUrlTemplate: PropTypes.string
}

export default ProductCard
