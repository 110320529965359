import { createComponent } from '@ycos/fela'

const BackgroundWrapper = createComponent(
  'BackgroundWrapper',
  ({ theme }) => {
    return {
      background: theme.typography.colors.SecondaryLightestGrey,
      height: '100%',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      'screen-large': {
        maxWidth: '1375px',
        minHeight: '600px'
      },
      'screen-medium': {
        minHeight: '540px'
      },
      'screen-small-max': {
        minHeight: '580px'
      }
    }
  },
  'div'
)

export default BackgroundWrapper
