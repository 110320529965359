import { createComponent } from '@ycos/fela'

const TakeoverTitle = createComponent(
  'TakeoverTitle',
  ({ theme }) => ({
    ...theme.homepage.heading,
    fontFamily: 'TheOutnetWebXL',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1em',
    letterSpacing: '0',
    textAlign: 'center',
    color: '#ffffff',
    'screen-large': {
      fontSize: '80px'
    },
    marginBottom: `${theme.spacingMultiplier * 4}px !important`,
    marginTop: '0 !important',
    ':empty': { display: 'none' }
  }),
  'h1'
)

export default TakeoverTitle
