import { trackOnTargetClickEditorial, createSiteMerchandisingEntry } from '../analytics-track'

export default (page, publicRuntimeConfig) => ({ item, parent }) => {
  const entry = createSiteMerchandisingEntry(item, page.pageKey, parent, item.additionalCTA)
  if (!entry) return

  const {
    brand: { brandId: brand },
    gtmAnalytics
  } = publicRuntimeConfig

  const onTargetClick = () => trackOnTargetClickEditorial(entry, gtmAnalytics, brand)

  return { onTargetClick }
}
