import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@ycos/component-grid'
import { hasTag, getTags } from '@ycos/coremedia-renderer'
import { breakpoints } from '@ycos/themes'
import { Picture } from '@ycos/picture'
import TakeoverTemplate from './TakeoverTemplate'
import BackgroundWrapper from './BackgroundWrapper'
import CenteredCta from '../component-cta/CenterdCta'
import { AmbientVideo } from '@ycos/component-video'
import { ContentInnerWrapper, ContentOuterWrapper } from './ContentWrapper'
import VisibilityWrapper from '../component-visibility'
import { createComponent } from '@ycos/fela'

const TakeOverImage = createComponent(
  'ImageWrapper',
  () => {
    return {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%'
    }
  },
  'div'
)

const TakeOverVideo = ({ soundPlaying, loop, config, ...rest }) => {
  const { dataURL, ynapParameter, brightCoveDataUrl, pictures = [] } = rest

  const { account, player } = config && config.brightcove
  const videoId = brightCoveDataUrl || ynapParameter || dataURL
  const playerID = soundPlaying ? player.regular : player.soundless
  const posterStyles = {
    position: 'absolute',
    top: 0
  }
  const pictureStyles = {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
  return (
    <>
      <style text="text/css">{`.vjs-errors-dialog{display:none;}`}</style>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <AmbientVideo
          account={account}
          playerId={playerID}
          videoId={videoId}
          pictures={pictures}
          lazy={false}
          loop={loop}
          playsInline
          autoPlay
          posterStyles={posterStyles}
          pictureStyles={pictureStyles}
        />
      </div>
    </>
  )
}

const PreventSSR = ({ children }) => {
  // prevents the video component to be rendered on server-side
  // using suppressHydrationWarning to silence an unavoidable error related to the imageUrls being different in server and client
  return (
    <div suppressHydrationWarning style={{ display: 'unset' }}>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

class TakeOver extends Component {
  constructor(props) {
    super(props)
    this.state = typeof window !== 'undefined' ? this.windowSize() : {}
  }

  windowSize = () => {
    const windowSize = window.innerWidth
    const mediumBreakpoint = breakpoints.medium.width
    const largeBreakpoint = breakpoints.large.width

    const isMobile = windowSize < mediumBreakpoint
    const isXLarge = windowSize >= largeBreakpoint

    return { isMobile, isXLarge }
  }

  selectVideo = (videos = []) => {
    const { isXLarge, isMobile } = this.state
    let video = null

    if (isXLarge) {
      const largeVideo = videos.filter(({ tags }) => hasTag(tags, 'Content.XLarge'))[0]
      video = largeVideo ? { ...largeVideo, width: 1375, height: 600 } : null
    } else if (isMobile) {
      const mobileVideo = videos.filter(({ tags }) => hasTag(tags, 'Content.isMobile'))[0]
      video = mobileVideo ? { ...mobileVideo, width: 767, height: 1095 } : null
    } else {
      const standardVideo = videos.filter(({ tags }) => !(hasTag(tags, 'Content.isMobile') || hasTag(tags, 'Content.XLarge')))[0]
      video = standardVideo || null
    }
    return video
  }

  selectUrl = ({ parameter = '', target }) => {
    const url = target.type === 'page' ? target.pageURL : target.categoryLink
    return `${url}${parameter.trim()}`
  }

  parseCTA = (cta) => {
    const href = this.selectUrl(cta)
    const teaserTitle = cta?.target?.teaserTextPlain || cta?.target?.teaserTitle

    return {
      href,
      teaserTitle,
      isHomepageHero: true,
      callToActionEnabled: cta.callToActionEnabled,
      callToActionCustomText: cta.callToActionCustomText,
      anchorPointName: cta.anchorPointName,
      anchorPointEnabled: cta.anchorPointEnabled
    }
  }

  handleClick = ({ cta, extraOnClick }) => {
    // Execute extraOnClick (tracking) and then pass the onTargetClick events to the handlers a bit further down
    extraOnClick && extraOnClick()
    return cta?.onTargetClick && cta?.onTargetClick
  }

  static layoutVariant = ['osc-takeover']

  render() {
    const { item, config, extraOnClick } = this.props
    const { tags, pictures, videos = [], preTitle, teaserTextPlain, teaserTitle, subTitlePlain, additionalCTA } = item
    const isColorInverse = hasTag(tags, 'Product.InverseColor')
    const contentRightAlign = hasTag(tags, 'Content.HeroContentRight')
    const contentCenterAlign = hasTag(tags, 'Content.HeroContentCenter')
    const contentLeftAlign = hasTag(tags, 'Content.HeroContentLeft')
    const videoControlsVisible = hasTag(tags, 'Content.VideoControlsVisible')
    const videoMuted = hasTag(tags, 'Content.VideoSoundOff')
    const videoLoop = hasTag(tags, 'Content.VideoLoop')
    const isDeviceSpecific = getTags(tags, 'DeviceType')
    const isDiscountOnTop = hasTag(tags, 'Content.DiscountOnTop')
    const video = this.selectVideo(videos)
    const onlyPictures = (pictures || []).filter((p) => p.type === 'image')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Grid.Row>
          <Grid.Span small={{ width: 6, offset: 0 }} large={{ width: 12, offset: 0 }}>
            <BackgroundWrapper>
              <PreventSSR>
                {!video && pictures && (
                  <TakeOverImage>
                    <Picture
                      images={onlyPictures}
                      lazy={false}
                      style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </TakeOverImage>
                )}
                {video && <TakeOverVideo {...video} config={config} controlsVisible={videoControlsVisible} loop={videoLoop} soundPlaying={!videoMuted} />}
                <ContentOuterWrapper additionalCTA={additionalCTA && this.parseCTA(additionalCTA[0])} video={!!video} extraOnClick={extraOnClick}>
                  <ContentInnerWrapper
                    contentLeftAlign={contentLeftAlign}
                    contentRightAlign={contentRightAlign}
                    contentCenterAlign={contentCenterAlign}
                    inverseColor={isColorInverse}
                  >
                    <TakeoverTemplate
                      isDiscountOnTop={isDiscountOnTop}
                      preTitle={preTitle}
                      teaserText={teaserTextPlain}
                      teaserTitle={teaserTitle}
                      subTitle={subTitlePlain}
                    />
                    {additionalCTA &&
                      additionalCTA.map((cta, i) => (
                        <CenteredCta key={i} item={this.parseCTA(cta)} onTargetClick={() => this.handleClick({ cta, extraOnClick })} />
                      ))}
                  </ContentInnerWrapper>
                </ContentOuterWrapper>
              </PreventSSR>
            </BackgroundWrapper>
          </Grid.Span>
        </Grid.Row>
      </VisibilityWrapper>
    )
  }
}
TakeOver.propTypes = {
  item: PropTypes.object
}
TakeOver.defaultProps = {
  children: [],
  tags: []
}
export default TakeOver
