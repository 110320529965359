import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { HeadingSmall } from '@ycos/primitives'
import { getTags } from '@ycos/coremedia-renderer'
import throttle from 'lodash.throttle'

import { getProductsListParameters } from '../../server/utils'
import layoutVariant from './layoutVariant'
import ProductCard from './ProductCard'
import VisibilityWrapper from '../component-visibility'

const Row = createComponent(
  'Row',
  () => ({
    zIndex: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    width: '100%',
    'screen-xlarge': {
      height: '610px'
    }
  }),
  'div'
)

export const HeadingTitleContainer = createComponent(
  'HeadingTitleContainer',
  ({ theme }) => ({
    marginBottom: `${2.5 * theme.spacingMultiplier}px`,
    textAlign: 'center'
  }),
  'div'
)

class ProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      fetchingStatus: false,
      finalProducts: props.products.items,
      pageNumber: props.products.pageNumber,
      totalPagesLimit: parseInt(getProductsListParameters(props.item.ynapParameter).totalPagesLimit),
      totalPagesAvailable: props.products.totalPages,
      productSearchParam: getProductsListParameters(props.item.ynapParameter)
    }
    this.rowContainerRef = React.createRef()
    this.throttledUpdateComponentSize = throttle(this.updateComponentSize, 100)
  }

  componentDidMount() {
    this.rowContainerRef && this.rowContainerRef.current.addEventListener('scroll', this.throttledUpdateComponentSize)
  }
  componentWillUnmount() {
    this.rowContainerRef && this.rowContainerRef.current.removeEventListener('scroll', this.throttledUpdateComponentSize)
  }

  getRightIndex = (index) => (index > 8 ? index % 8 : index)

  updateComponentSize = () => {
    const ref = this.rowContainerRef.current
    this.setState({
      currentIndex: this.getRightIndex(Math.round(ref.scrollLeft / ref.clientWidth))
    })
  }

  static layoutVariant = layoutVariant

  render() {
    const { locale, brand, onTargetClick, productUrl, item, onProductClick } = this.props
    const { finalProducts } = this.state
    const { tags } = item
    const isDeviceSpecific = getTags(tags, 'DeviceType')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        {item.teaserTitle && (
          <HeadingTitleContainer>
            <HeadingSmall additionalStyles={{ textTransform: 'none' }}>{item.teaserTitle}</HeadingSmall>
          </HeadingTitleContainer>
        )}
        <Row innerRef={this.rowContainerRef}>
          {finalProducts &&
            finalProducts.map((product, i) => (
              /* TODO - replace with @ynap/product-summary (currently doing so requires a fair few dependancy upgrades) */
              <ProductCard
                product={product}
                key={i}
                index={i}
                locale={locale}
                brand={brand}
                onTargetClick={onTargetClick}
                onProductClick={onProductClick}
                productUrlTemplate={productUrl.productUrlTemplate}
              />
            ))}
        </Row>
      </VisibilityWrapper>
    )
  }
}

ProductCarousel.propTypes = {
  item: PropTypes.shape({
    tags: PropTypes.array,
    teaserTitle: PropTypes.string
  }),
  brand: PropTypes.string,
  locale: PropTypes.object,
  onTargetClick: PropTypes.func,
  onProductClick: PropTypes.func,
  products: PropTypes.shape({
    items: PropTypes.array,
    pageNumber: PropTypes.number,
    totalPages: PropTypes.number
  })
}
ProductCarousel.defaultProps = {
  products: {
    items: [],
    pageNumber: 0,
    totalPages: 0
  }
}

export default ProductCarousel
