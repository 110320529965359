import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@ycos/component-grid'
import { createComponent } from '@ycos/fela'
import { getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const Container = createComponent(
  'Container',
  () => ({
    width: '100%'
  }),
  'div'
)

class FourColumn extends Component {
  static layoutVariant = ['osc-four-column']

  render() {
    const { children, item } = this.props
    const { tags } = item
    const isDeviceSpecific = getTags(tags, 'DeviceType')
    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Grid.Row>
          <Grid.Span small={{ width: 3, offset: 0 }} medium={{ width: 3, offset: 0 }}>
            <Container>{children[0]}</Container>
          </Grid.Span>
          <Grid.Span small={{ width: 3, offset: 0 }} medium={{ width: 3, offset: 0 }}>
            <Container>{children[1]}</Container>
          </Grid.Span>
          <Grid.Span
            additionalStyles={{ marginTop: '12px', 'screen-medium': { marginTop: '0' } }}
            small={{ width: 3, offset: 0 }}
            medium={{ width: 3, offset: 0 }}
          >
            <Container>{children[2]}</Container>
          </Grid.Span>
          <Grid.Span
            additionalStyles={{ marginTop: '12px', 'screen-medium': { marginTop: '0' } }}
            small={{ width: 3, offset: 0 }}
            medium={{ width: 3, offset: 0 }}
          >
            <Container>{children[3]}</Container>
          </Grid.Span>
        </Grid.Row>
      </VisibilityWrapper>
    )
  }
}

FourColumn.propTypes = {
  children: PropTypes.array
}

FourColumn.defaultProps = {
  children: []
}

export default FourColumn
