import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { hasTag, getTags } from '@ycos/coremedia-renderer'
import { breakpoints } from '@ycos/themes'
import VisibilityWrapper from '../component-visibility'

const ImageGridContainer = createComponent(
  'ImageGridContainer',
  ({ heroImageLeft, heroImageMiddle, heroImageRight }) => ({
    padding: '0 10px',
    margin: '0 auto',
    'screen-medium': {
      height: 'auto',
      overflow: 'hidden',
      display: 'grid',
      gridTemplateColumns: (heroImageLeft && '6fr 3fr 3fr') || (heroImageMiddle && '3fr 6fr 3fr') || (heroImageRight && '3fr 3fr 6fr'),
      gridTemplateRows: 'repeat(2, 48%)',
      gridGap: '20px',
      padding: '0 10px',
      'screen-large': {
        gridAutoRows: '290px',
        maxWidth: '960px',
        margin: '-24px auto -10px'
      }
    }
  }),
  'div'
)

const HeroImageContainer = createComponent(
  'HeroImageContainer',
  ({ heroImageLeft, heroImageMiddle, heroImageRight }) => ({
    margin: '0 auto',
    maxWidth: '405px',
    'screen-medium': {
      maxWidth: '100%',
      margin: '0',
      overflow: 'hidden',
      gridRowStart: '1',
      gridAutoRow: '',
      gridRowEnd: '3',
      gridColumnStart: (heroImageLeft && '1') || (heroImageMiddle && '2') || (heroImageRight && '3'),
      marginLeft: '0',
      marginRight: '0',
      '> *': {
        width: '100%',
        height: '100%',
        '> div': {
          width: '100%',
          height: '100%',
          '> picture': {
            width: '100%',
            height: '100%',
            '> img': {
              width: '100%',
              height: '100%'
            }
          }
        }
      }
    }
  }),
  'div'
)

const ImageColumnContainer = createComponent('ImageColumnContainer', () => ({
  overflow: 'hidden',
  margin: '0',
  '> *': {
    width: '100%',
    '> div': {
      width: '100%'
    }
  }
}))

class ImageGrid extends Component {
  state = {
    isMobile: false
  }

  componentDidMount() {
    this.windowSize()
    window.addEventListener('resize', this.windowSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowSize)
  }

  windowSize = () => {
    const windowSize = window.innerWidth
    const mediumBreakpoint = breakpoints.medium.width

    windowSize < mediumBreakpoint ? this.setState({ isMobile: true }) : this.setState({ isMobile: false })
  }

  static layoutVariant = ['osc-image-grid']

  render() {
    const { isMobile } = this.state
    const { item } = this.props
    const { pictures = [], videos = [], tags = '' } = item
    const heroImageLeft = hasTag(tags, 'Content.HeroImageLeft')
    const heroImageMiddle = hasTag(tags, 'Content.HeroImageMiddle')
    const heroImageRight = hasTag(tags, 'Content.HeroImageRight')
    const hasContent = !!(pictures || hasTag || videos)
    const isDeviceSpecific = getTags(tags, 'DeviceType')
    return isMobile ? (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        {hasContent && (
          <ImageGridContainer additionalStyle={{ justifyContent: 'center' }}>
            <HeroImageContainer>{this.props.children[0]}</HeroImageContainer>
          </ImageGridContainer>
        )}
      </VisibilityWrapper>
    ) : (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        {hasContent && (
          <ImageGridContainer heroImageLeft={heroImageLeft} heroImageMiddle={heroImageMiddle} heroImageRight={heroImageRight}>
            <HeroImageContainer heroImageLeft={heroImageLeft} heroImageMiddle={heroImageMiddle} heroImageRight={heroImageRight}>
              {this.props.children[0]}
            </HeroImageContainer>
            <ImageColumnContainer>{this.props.children[1]}</ImageColumnContainer>
            <ImageColumnContainer>{this.props.children[2]}</ImageColumnContainer>
            <ImageColumnContainer>{this.props.children[3]}</ImageColumnContainer>
            <ImageColumnContainer>{this.props.children[4]}</ImageColumnContainer>
          </ImageGridContainer>
        )}
      </VisibilityWrapper>
    )
  }
}

ImageGrid.propTypes = {
  children: PropTypes.array
}

ImageGrid.defaultProps = {
  children: [],
  tags: []
}

export default ImageGrid
