import React from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { Link, Caption } from '@ycos/primitives'
import url from 'url'
import EditorialCTA from './EditorialCTA'
import HomepageHeroButton from './HomepageHeroCTA'
import { getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const Container = createComponent(
  'Container',
  () => ({
    margin: '8px auto 8px auto',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    maxWidth: '267px'
  }),
  'span'
)

const CtaCaption = createComponent(
  'CtaCaption',
  ({ theme }) => ({
    marginTop: `${theme.spacingMultiplier}px`
  }),
  Caption
)

const getLinkType = (brand, href = '') => {
  const stringMatches = {
    nap: 'net-a-porter',
    mrp: 'mrporter'
  }

  const parsedUrl = url.parse(href)
  let useSameTab = true
  if (parsedUrl.host !== null && !parsedUrl.host.includes(stringMatches[brand]) && !href.startsWith('mailto')) {
    useSameTab = false
  }

  return useSameTab ? '_self' : '_blank'
}

const CenteredCta = (props) => {
  const { brand, onTargetClick, disabled } = props
  const {
    teaserTitle,
    href,
    subTitlePlain,
    isHomepageHero,
    ynapParameter = '',
    callToActionEnabled,
    callToActionCustomText,
    anchorPointEnabled,
    anchorPointName,
    tags
  } = props.item
  const linkTargetType = getLinkType(brand, href, ynapParameter)
  const ctaLink = disabled || !href ? null : href + ynapParameter
  const isDeviceSpecific = getTags(tags, 'DeviceType')

  return (
    <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
      <Container>
        <div>
          <Link data-automation="isc-cta-secondary" href={ctaLink} onClick={onTargetClick} target={linkTargetType}>
            {isHomepageHero && (
              <HomepageHeroButton disabled={disabled} data-extra-text={callToActionEnabled ? callToActionCustomText : null}>
                {anchorPointEnabled ? anchorPointName : teaserTitle}
              </HomepageHeroButton>
            )}
            {!isHomepageHero && (
              <EditorialCTA disabled={disabled} data-extra-text={callToActionEnabled ? callToActionCustomText : null}>
                {anchorPointEnabled ? anchorPointName : teaserTitle}
              </EditorialCTA>
            )}
          </Link>
          {subTitlePlain && <CtaCaption>{subTitlePlain}</CtaCaption>}
        </div>
      </Container>
    </VisibilityWrapper>
  )
}

CenteredCta.layoutVariant = ['osc-centered-cta']

CenteredCta.propTypes = {
  item: PropTypes.shape({
    layoutVariant: PropTypes.string,
    teaserTitle: PropTypes.string,
    target: PropTypes.shape({
      href: PropTypes.string,
      teaserTitle: PropTypes.teaserTitle
    })
  }),
  brand: PropTypes.string,
  onTargetClick: PropTypes.func
}

CenteredCta.defaultProps = {
  item: {},
  brand: 'Ton',
  onTargetClick: () => {}
}

export default CenteredCta
