const applyRulesToProps = (rules) => (props) => {
  return rules.reduce((accumulator, currentValue) => {
    const additionPropsFromRule = currentValue({ ...props, ...accumulator }) || {}
    return Object.assign(accumulator, additionPropsFromRule)
  }, {})
}

const fixChildrenRules = (rules) => {
  const applyRules = applyRulesToProps(rules)
  return [
    (props) => {
      if (props.item.layoutVariant === 'osc-content-carousel') {
        props.item.items = props.item.items.map((item) => {
          const childProps = applyRules({ item })
          childProps.item.onClick = childProps.onClick || (() => {})
          return childProps.item
        })
        return props
      }
      return applyRules(props)
    }
  ]
}

export default fixChildrenRules
