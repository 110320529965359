import { createComponent } from '@ycos/fela'
import { ButtonOutline } from '@ycos/primitives'

const EditorialCTA = createComponent(
  'EditorialCTA',
  ({ theme }) => ({
    borderRadius: '0',
    letterSpacing: '1px',
    height: '48px',

    ...theme.homepage.ctaHover
  }),
  ButtonOutline,
  ['data-extra-text']
)

export default EditorialCTA
