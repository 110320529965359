import { createComponent } from '@ycos/fela'
import { ButtonOutline } from '@ycos/primitives'

const HomepageHeroCTA = createComponent(
  'HomepageHeroCTA',
  ({ theme }) => ({
    border: 'none',
    borderRadius: '0',
    height: '48px ',
    'screen-small-max': {
      width: `calc(100% - ${theme.spacingMultiplier * 5.5}px)`
    },
    ...theme.homepage.ctaHover
  }),
  ButtonOutline,
  ['data-extra-text']
)

export default HomepageHeroCTA
