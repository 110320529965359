const mapMedia = (baseUrl) => (picture) => {
  if (picture && picture.imageUrl && picture.imageUrl.indexOf('http') === -1) {
    return {
      ...picture,
      imageUrl: `${baseUrl}${picture.imageUrl}`
    }
  }
  if (picture.type === 'video' && picture.pictures) {
    return {
      ...picture,
      pictures: picture.pictures.map(mapMedia(baseUrl))
    }
  }
  return picture
}

export const imagesphereUrlRule = (baseUrl) => {
  return (props) => {
    const { item } = props
    if (item.pictures) {
      item.pictures = item.pictures.map(mapMedia(baseUrl))
    }
    if (item.videos) {
      item.videos = item.videos.map(mapMedia(baseUrl))
    }
    return props
  }
}
