import React from 'react'
import TakeoverSubtitle from './TakeoverSubtitle'
import PreTitleText from './PreTitleText'
import TakeoverText from './TakeoverText'
import TakeoverTitle from './TakeoverTitle'
import TextContentWrapper from './TextContentWrapper'

const TakeoverTemplate = ({ isDiscountOnTop, preTitle, teaserText, teaserTitle, subTitle }) => {
  return !isDiscountOnTop ? (
    <TextContentWrapper>
      <PreTitleText>{preTitle}</PreTitleText>
      <TakeoverTitle>{teaserTitle}</TakeoverTitle>
      {subTitle?.length > 0 && <TakeoverSubtitle>{subTitle}</TakeoverSubtitle>}
      <TakeoverText>{teaserText}</TakeoverText>
    </TextContentWrapper>
  ) : (
    <TextContentWrapper>
      {subTitle?.length > 0 && <TakeoverSubtitle>{subTitle}</TakeoverSubtitle>}
      <TakeoverTitle>{teaserTitle}</TakeoverTitle>
      <TakeoverText>{teaserText}</TakeoverText>
    </TextContentWrapper>
  )
}

export default TakeoverTemplate
