import React, { PureComponent } from 'react'
import { object, shape, array } from 'prop-types'
import { createComponent } from '@ycos/fela'
import { formatPrice } from '@ynap/intl-formatters'
import { createFontStyle, getFontStyles } from '@ycos/primitives'

function calculatePrice(amount, divisor) {
  return amount / divisor
}

const ProductPriceBox = createComponent(
  'createComponent',
  () => ({
    textAlign: 'center',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical'
  }),
  'div'
)

const Price = createFontStyle('Price', { type: 'Label', tagName: 'p', name: '6' }, ({ theme }) => ({
  color: theme.typography.colors.SecondaryRed,
  margin: `${theme.spacingMultiplier}px 0 0 0`
}))

const SalePrice = createFontStyle('SalePrice', { type: 'Label', tagName: 'p', name: '1' }, ({ theme }) => ({
  color: theme.typography.colors.PrimaryBlack,
  margin: `${theme.spacingMultiplier / 2}px 0 0 0`
}))

const Badge = createComponent(
  'Badge',
  ({ theme }) => {
    const {
      typography,
      custom: { productCard },
      spacingMultiplier
    } = theme
    return {
      ...getFontStyles(typography, { type: productCard.badge.type, name: '3' }),
      margin: `${spacingMultiplier}px 0 ${spacingMultiplier / 2}px 0`,
      color: productCard.badge.color,
      fontWeight: 'bold'
    }
  },
  'p'
)

export const ConsideredBadge = createComponent(
  'ConsideredBadge',
  ({ theme }) => {
    const {
      typography,
      custom: { productCard },
      spacingMultiplier
    } = theme
    return {
      ...getFontStyles(typography, { type: productCard.consideredBadge.type, name: productCard.consideredBadge.name }),
      margin: `${spacingMultiplier}px 0 ${spacingMultiplier / 2}px 0`,
      color: productCard.consideredBadge.color,
      border: productCard.consideredBadge.border,
      borderRadius: productCard.consideredBadge.borderRadius,
      padding: '0 8px',
      width: 'fit-content',
      height: '22px',
      verticalAlign: 'middle',
      display: 'inline-block',
      fontWeight: 'bold'
    }
  },
  'p'
)

export const extractFirstBadge = (badges = []) => {
  return badges.reduce((acc, badge) => {
    acc.push(badge)
    return acc
  }, [])[0]
}

class ProductPrice extends PureComponent {
  render() {
    const { price, badges, locale, considered } = this.props

    const currency = price?.currency
    const rdWasPrice = price?.rdWasPrice
    const sellingPrice = price?.sellingPrice
    const rdDiscount = price?.rdDiscount

    const productBadge = extractFirstBadge(badges)
    const percentageLabel = locale.language === 'ar' ? '%' : '% off'
    // eslint-disable-next-line import/no-dynamic-require
    const getMessages = (locale) => require(`./messages/${locale.language}.json`)

    return (
      <ProductPriceBox data-automation="price">
        {sellingPrice && currency && (
          <Price data-automation="original">{formatPrice(calculatePrice(sellingPrice.amount, sellingPrice.divisor), currency.label, locale.id)}</Price>
        )}
        {rdWasPrice && rdDiscount && currency && (
          <SalePrice data-automation="sale">
            <s>{formatPrice(calculatePrice(rdWasPrice.amount, rdWasPrice.divisor), currency.label, locale.id)}</s>
            <span>{` ${calculatePrice(rdDiscount.amount, rdDiscount.divisor)}${percentageLabel}`}</span>
          </SalePrice>
        )}

        {productBadge && (
          <Badge key={productBadge.key} data-automation="badge">
            {productBadge.label}
          </Badge>
        )}

        {considered && (
          <ConsideredBadge key={considered} data-automation="considered-badge">
            {getMessages(locale).considered}
          </ConsideredBadge>
        )}
      </ProductPriceBox>
    )
  }
}

ProductPrice.defaultProps = {
  price: null
}

ProductPrice.propTypes = {
  price: shape({
    currency: object,
    rdSellingPrice: object,
    sellingPrice: object,
    badges: array
  }).isRequired
}

export default ProductPrice
