import { createComponent } from '@ycos/fela'

const TextContentWrapper = createComponent(
  'TextContentWrapper',
  () => ({
    ':empty': { display: 'none' },
    'screen-large': {
      width: '600px'
    }
  }),
  'div'
)

export default TextContentWrapper
