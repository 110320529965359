import React, { Fragment, Component } from 'react'
import { shape, string, func, bool, number, oneOfType } from 'prop-types'
import { createOrGetVideo } from '@ycos/brightcove'
import { createComponent } from '@ycos/fela'
import { Caption } from '@ycos/primitives'
import { trackVideoProgress } from '../../analytics-track'

const getPaddingFromAspectRatio = (aspectRatio) => {
  const [height, width] = aspectRatio.split(':')
  return (width / height) * 100
}

export const VideoContainer = createComponent('VideoContainer', ({ aspectRatio }) => {
  let positionValue = 'absolute'
  let paddingTopValue = 'unset'

  if (aspectRatio) {
    positionValue = 'relative'
    paddingTopValue = `${getPaddingFromAspectRatio(aspectRatio)}%`
  }
  return {
    maxWidth: '100%',
    height: '100%',
    display: 'block',
    width: '100%',
    position: positionValue,
    paddingTop: paddingTopValue
  }
})

export const VideoCaption = createComponent(
  'VideoCaption',
  ({ theme }) => ({
    marginTop: `${theme.spacingMultiplier}px`
  }),
  Caption
)

class StandardVideo extends Component {
  constructor(props) {
    super(props)
    this.videoNode = React.createRef()
    this.createOrGetVideo = createOrGetVideo.bind(this)
    this.state = {
      videoError: false,
      screenOrientation: 'landscape',
      observer: null
    }
  }

  componentDidMount() {
    const { config, brightcove, soundless } = this.props
    const { account, player } = (config && config.brightcove) || brightcove
    // Backwards compatibility
    const playerID = (soundless ? player.soundless : player.regular) || player

    this.createOrGetVideo(account, playerID, this.videoNode.current).then((videoPlayerInstance) => {
      // Checking if the video player has loaded with out any errors
      if (videoPlayerInstance.error_) {
        videoPlayerInstance.errorDisplay.dispose()
        this.setState({
          videoError: true
        })
      }
      if (videoPlayerInstance) {
        this.props.onVideoPlay && videoPlayerInstance.on('play', this.props.onVideoPlay)
        this.props.onVideoEnded && videoPlayerInstance.on('ended', this.props.onVideoEnded)
        window.addEventListener('orientationchange', this.setScreenOrientation.bind(this))
        this.setVideoIntersectionObservers(videoPlayerInstance)
      }
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.visible !== undefined && nextProps.visible !== undefined && this.props.visible !== nextProps.visible) {
      this.stopVideo()
    }

    if (this.state.screenOrientation !== nextState.screenOrientation) {
      return true
    }

    if (JSON.stringify(this.props.item) === JSON.stringify(nextProps.item)) {
      return false
    }

    return true
  }

  componentDidUpdate() {
    const { config, brightcove, soundless } = this.props
    const { account, player } = (config && config.brightcove) || brightcove
    // Backwards compatibility
    const playerID = (soundless ? player.soundless : player.regular) || player

    this.createOrGetVideo(account, playerID, this.videoNode.current).then((videoPlayerInstance) => {
      this.setVideoIntersectionObservers(videoPlayerInstance)
    })
  }

  setScreenOrientation = () => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      this.setState({
        screenOrientation: 'portrait'
      })
    } else
      this.setState({
        screenOrientation: 'landscape'
      })

    if (window.matchMedia('(orientation: landscape)').matches) {
      this.setState({
        screenOrientation: 'landscape'
      })
    }
  }

  isPortraitMode = () => {
    const { screenOrientation } = this.state
    return screenOrientation === 'portrait'
  }

  stopVideo = (videoNode) => {
    const localNode = videoNode ? document.getElementById(videoNode.id) : this.videoNode.current
    if (localNode) {
      localNode.pause()
    }
  }

  resumeVideo = (videoNode) => {
    const localNode = videoNode ? document.getElementById(videoNode.id) : this.videoNode.current
    if (localNode) {
      localNode.play()
    }
  }

  trackVideoPlayback = (videoNode) => {
    this.setState({
      currentTime: videoNode.currentTarget.currentTime ? videoNode.currentTarget.currentTime : 0
    })

    const endThreshold = 0.95
    const videoEnded = videoNode?.currentTarget?.duration * endThreshold <= this.state.currentTime

    if (videoEnded && !this.state.playbackCompleted) {
      this.setState({
        playbackCompleted: true
      })
      trackVideoProgress(this.props.item, true, 'ton', 'end')
    }
  }

  onPlayHandler = () => {
    // checks if its the first play event or the user just skipped the video, triggering a new onplay evt
    if (!this.state.currentTime) {
      trackVideoProgress(this.props.item, true, 'ton', 'start')
    }
  }

  setVideoIntersectionObservers(playerInstance) {
    const { observerInstance, videoId } = this.props
    const localNode = this.videoNode.current
    const DOMElement = localNode && localNode.id ? document.getElementById(localNode.id) : null

    playerInstance.ready(() => {
      playerInstance.catalog.getVideo(videoId, (error, video) => {
        playerInstance.catalog.load(video)

        playerInstance.on('loadedmetadata', () => {
          playerInstance.autoplay(false)

          const options = {
            threshold: DOMElement && window.innerHeight < DOMElement.offsetHeight ? 0.25 : 0.5
          }

          if (observerInstance) {
            observerInstance.unobserve(document.getElementById(localNode.id))
          }

          const observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio < options.threshold) {
              this.stopVideo(localNode)
            } else {
              this.resumeVideo(localNode)
            }
          }, options)

          if (localNode) {
            observer.observe(document.getElementById(localNode.id))
          }

          this.setState({
            observer
          })
        })
      })
    })
  }

  static layoutVariant = 'isc-video'

  render() {
    const { aspectRatio, item, config, brightcove, soundless, loop, controls, videoId } = this.props
    const { videoError } = this.state
    const { detailTextPlain } = item
    const { account, player } = (config && config.brightcove) || brightcove
    const playerID = (soundless ? player.soundless : player.regular) || player

    const videoStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: videoError ? '#000' : 'transparent', // Sets transparency to fix subpixels on video resize
      objectFit: 'cover'
    }

    return (
      <Fragment>
        <VideoContainer aspectRatio={aspectRatio}>
          <video
            ref={this.videoNode}
            data-video-id={videoId}
            data-account={account}
            data-player={playerID}
            data-embed="default"
            data-application-id
            className="video-js"
            style={videoStyle}
            loop={loop}
            controls={controls}
            playsInline
            onTimeUpdate={this.trackVideoPlayback}
            onPlay={this.onPlayHandler}
          />
        </VideoContainer>
        {detailTextPlain && <VideoCaption>{detailTextPlain}</VideoCaption>}
      </Fragment>
    )
  }
}

StandardVideo.defaultProps = {
  visible: false,
  soundless: false,
  loop: false,
  controls: true,
  brightcove: {
    account: '88951765001',
    player: {
      regular: 'SJsri37BW',
      soundless: 'HkVWbtmsM'
    }
  },
  aspectRatio: '16:9'
}

StandardVideo.propTypes = {
  visible: bool,
  soundless: bool,
  loop: bool,
  controls: bool,
  brightcove: shape({
    account: string,
    player: oneOfType([
      shape({
        regular: string,
        soundless: string
      }),
      string
    ])
  }),
  aspectRatio: string,
  onVideoPlay: func,
  onVideoEnded: func,
  config: shape({
    brightcove: shape({
      account: number,
      player: shape({
        regular: string,
        soundless: string
      })
    })
  })
}

export default StandardVideo
